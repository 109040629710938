import React from 'react'
import ReactDOM from 'react-dom'
import Map from './components/Map'

const mapStyles = ['mapbox://styles/mapbox/streets-v11']

const Application = () => {
  const mapStyle = mapStyles[0]
  return <Map mapStyle={mapStyle} />
}

ReactDOM.render(<Application />, document.getElementById('app'))
