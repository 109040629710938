import { memo } from 'react'
import React from 'react'
import ReactMapGL from 'react-map-gl'
import Markers from './Markers'
import { MAPBOX_ACCESS_TOKEN } from '../config'

interface IProps {
  mapStyle: string
}

export default memo((props: IProps) => {
  const { mapStyle } = props

  const [viewState, setViewState] = React.useState({
    longitude: 4.952471257463031,
    latitude: 45.730772650670616,
    zoom: 16
  })

  return (
    <ReactMapGL
      {...viewState}
      onMove={evt => setViewState(evt.viewState)}
      mapboxAccessToken={MAPBOX_ACCESS_TOKEN}
      mapStyle={mapStyle}
      style={{ width: '100vw', height: '100vh' }}
    >
      <Markers />
    </ReactMapGL>
  )
})
