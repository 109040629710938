import React, { memo } from 'react'
import { Marker, Popup } from 'react-map-gl'
import 'mapbox-gl/dist/mapbox-gl.css'
import { MARKER_SIZE } from '../config'
import { ReactComponent as IconCross } from '../assets/icon-cross.svg'
import './image-marker.css'

interface IProps {
  latitude: number
  longitude: number
  imageSource: string
  imageAlt: string
  title: string
  info: string
}

export const ImageMarker = memo((props: IProps) => {
  const { latitude, longitude, imageSource, imageAlt, title, info } = props
  const [isPopupVisible, setIsPopupVisible] = React.useState(false)

  const closePopup = () => {
    setIsPopupVisible(false)
  }

  const showPopup = () => {
    setIsPopupVisible(true)
  }

  return (
    <>
      <Marker longitude={longitude} latitude={latitude} onClick={showPopup} anchor={'bottom'}>
        <img src={imageSource} alt={imageAlt} style={{ maxHeight: MARKER_SIZE, maxWidth: MARKER_SIZE }} />
        {isPopupVisible && (
          <Popup
            latitude={latitude}
            longitude={longitude}
            closeButton={false}
            onClose={closePopup}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
              padding: '16px',
              gap: '8px',
              width: '240px',
              background: '#FFFFFF',
              border: '1px solid #E6E6E6',
              borderRadius: '16px'
            }}
            offset={MARKER_SIZE + 4}
          >
            <InformationPopup closePopup={closePopup} title={title} info={info} />
          </Popup>
        )}
      </Marker>
    </>
  )
})

const InformationPopup = (props: { closePopup: () => void; title: string; info: string }) => {
  const { closePopup, title, info } = props
  return (
    <div style={{ display: 'flex', flexDirection: 'row', alignSelf: 'center', justifyContent: 'space-between' }}>
      <div>
        <PopupTitle>{title}</PopupTitle>
        <PopupInfo>{info}</PopupInfo>
      </div>
      <IconCross onClick={closePopup} style={{ width: 16, height: 16, cursor: 'pointer' }} />
    </div>
  )
}

const PopupTitle = (props: { children: React.ReactNode }) => (
  <p
    style={{
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 700,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#0F0A0A'
    }}
  >
    {props.children}
  </p>
)

const PopupInfo = (props: { children: React.ReactNode }) => (
  <p
    style={{
      fontFamily: 'Open Sans',
      fontStyle: 'normal',
      fontWeight: 400,
      fontSize: '14px',
      lineHeight: '24px',
      color: '#0F0A0A'
    }}
  >
    {props.children}
  </p>
)
