import React, { memo } from 'react'
import 'mapbox-gl/dist/mapbox-gl.css'
import IconPodium from '../assets/icon-podium.png'
import LogoBihr from '../assets/logo-bihr.png'
import LogoEnvie2Rouler from '../assets/logo-envie-2-rouler.png'
import LogoFlashbird from '../assets/logo-flashbird.png'
import LogoFMA from '../assets/logo-fma.png'
import LogoInMotion from '../assets/logo-in-motion.png'
import LogoLibertyRider from '../assets/logo-liberty-rider.png'
import LogoMetzeller from '../assets/logo-metzeller.png'
import LogoMutuelleDesMotards from '../assets/logo-mutuelle-des-motards.png'
import LogoSena from '../assets/logo-sena.png'
import LogoSollyAzar from '../assets/logo-solly-azar.png'
import LogoSpeedway from '../assets/logo-speedway.png'
import { ImageMarker } from './ImageMarker'

type Props = {}

export default memo((props: Props) => {
  const treasures = [
    {
      id: 0,
      latitude: 45.73175788963215,
      longitude: 4.952059980828977,
      imageSource: IconPodium,
      imageAlt: 'Icon Podium',
      title: 'Podium remise des lots',
      info: 'Hall 3 - Stand 3A2'
    },
    {
      id: 1,
      latitude: 45.73039318613391,
      longitude: 4.952124293104674,
      imageSource: LogoBihr,
      imageAlt: 'Logo Bihr',
      title: 'Bihr',
      info: 'Hall 6 - Stand 6B25'
    },
    {
      id: 2,
      latitude: 45.73207886700624,
      longitude: 4.952406541137464,
      imageSource: LogoEnvie2Rouler,
      imageAlt: 'Logo Envie2Rouler',
      title: 'Envie2rouler',
      info: 'Hall 3 - Stand A15'
    },
    {
      id: 3,
      latitude: 45.732255388815844,
      longitude: 4.952906158106828,
      imageSource: LogoFlashbird,
      imageAlt: 'Logo Flashbird',
      title: 'Flashbird',
      info: 'Hall 3 - Stand 3D3'
    },
    {
      id: 4,
      latitude: 45.7296384252318,
      longitude: 4.951487492639444,
      imageSource: LogoFMA,
      imageAlt: 'Logo FMA',
      title: 'FMA',
      info: 'Hall 6 - Stand 6C7'
    },
    {
      id: 5,
      latitude: 45.72985801065124,
      longitude: 4.953017184100247,
      imageSource: LogoInMotion,
      imageAlt: 'Logo In&Motion',
      title: 'In&motion',
      info: 'Hall 5 - Stand 5D10'
    },
    {
      id: 6,
      latitude: 45.73006037292069,
      longitude: 4.951444315863,
      imageSource: LogoLibertyRider,
      imageAlt: 'Logo Liberty Rider',
      title: 'Liberty Rider ',
      info: 'Hall 6 - Stand B6'
    },
    {
      id: 7,
      latitude: 45.729509256934904,
      longitude: 4.953393438854334,
      imageSource: LogoMetzeller,
      imageAlt: 'Logo Metzeller',
      title: 'Metzeler',
      info: 'Hall 5 - Stand 5F3'
    },
    {
      id: 8,
      latitude: 45.731162588578286,
      longitude: 4.952357196251768,
      imageSource: LogoMutuelleDesMotards,
      imageAlt: 'Logo Mutuelle des Motards',
      title: 'Mutuelle des Motards',
      info: 'Hall 4.1 - Stand 4A10'
    },
    {
      id: 9,
      latitude: 45.730292873346144,
      longitude: 4.9535599778446056,
      imageSource: LogoSena,
      imageAlt: 'Logo Sena',
      title: 'Sena',
      info: 'Hall 5 - Stand 5C5'
    },
    {
      id: 10,
      latitude: 45.72995273350705,
      longitude: 4.9541644526958635,
      imageSource: LogoSollyAzar,
      imageAlt: 'Logo Solly Azar',
      title: 'Solly Azar',
      info: 'Hall 5 - Stand E13'
    },
    {
      id: 11,
      latitude: 45.731425222694185,
      longitude: 4.952788964003076,
      imageSource: LogoSpeedway,
      imageAlt: 'Logo Speedway',
      title: 'Speedway',
      info: 'Hall 4 - Stand 4C6'
    }
  ]

  return (
    <>
      {treasures.map(treasure => {
        const { id, latitude, longitude, imageAlt, imageSource, title, info } = treasure
        return (
          <ImageMarker
            key={id}
            latitude={latitude}
            longitude={longitude}
            imageAlt={imageAlt}
            imageSource={imageSource}
            title={title}
            info={info}
          />
        )
      })}
    </>
  )
})
